@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO(DBB) : Remove tailwind completely */

html,
body,
#root,
#root>div {
  height: 100%;
  background-color: #FDFBFF;
}

body {
  overflow-y: scroll;
}

a {
  color: inherit;
  text-decoration: none;
}